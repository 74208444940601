<template>
  <div class="input-date-width">
    <v-menu
      :close-on-content-click="false"
      v-model="menu2"
      :nudge-right="40"
      lazy
      transition="scale-transition"
      offset-y
      full-width
      min-width="290px"
      max-width="290px"
      z-index="1300"
      :disabled="disabled"
    >
      <v-text-field
        :ref="'refDateInput'"
        slot="activator"
        :label="label"
        prepend-inner-icon="event"
        :rules="rules"
        :readonly="disabled"
        :clearable="clearable"
        v-char-length="charLenght"
        v-model="formattedValue"
        @change="inputChage"
        @click:clear="onInput"
        :return-masked-value="true"
        :mask="dateMask"
        v-on:keydown.native="keyEventDateInput"
        @dblclick="dblclick"
        class="date-text-input"
        hide-details
      />
      <v-date-picker z-index="1300"
      :value="dateValue" :locale="parsedLocale" :first-day-of-week="1" @input="onInput" @change="datePickerChange" :readonly="disabled"/>
    </v-menu>
  </div>
</template>

<script>
import Arrowable from '../mixins/Arrowable.vue';
import { keyEvents } from '../keyEvents';

export default {
  mixins: [Arrowable],
  props: {
    value: { required: true },
    label: { type: String },
    rules: { type: Array },
    disabled: { type: Boolean },
    clearable: { type: Boolean, default: false },
    locale: { type: String },
    charLenght: { type: Number, default: 10 },
    ignoreTimezones: { type: Boolean, default: false },
  },
  data() {
    return {
      menu2: false,
      dateMoment: null,
      formattedValue: '',
      dateMask: '##/##/##',
    };
  },
  computed: {
    parsedLocale() {
      switch (this.locale) {
        case ('es'):
          return 'es-ES';
        case ('en'):
          return 'en-US';
        default:
          return this.locale;
      }
    },
    dateValue() {
      return this.dateMoment ? this.dateMoment.format('YYYY-MM-DD') : null;
    },
  },
  mounted() {
    this.dateMoment = this.value ? this.$moment(this.value) : null;
    this.setFormattedDateFromDateMoment();
  },
  methods: {
    keyEventDateInput($event) {
      this.keyEvent($event);

      if ($event.code === keyEvents.UP || $event.code === keyEvents.DOWN) {
        if (this.dateMoment) {
          const dateMomentAux = this.$moment(this.dateMoment);
          dateMomentAux.add($event.code === keyEvents.UP ? 1 : -1, 'days');
          this.onInput(dateMomentAux);
        }
      }

      if ($event.code === keyEvents.TAB) {
        this.inputChage();
      }
    },
    onInput(value) {
      this.dateMoment = (this.ignoreTimezones)
        ? this.$moment(value).tz('UTC', true)
        : this.$moment(value);
      this.setFormattedDateFromDateMoment();
      this.menu2 = false;
      this.emitInput();
    },
    datePickerChange(value) {
      this.$emit('change', value);
    },
    inputChage() {
      this.dateMoment = this.formattedValue ? this.$moment(this.formattedValue, 'DD/MM/YY') : null;
      this.emitInput();
    },
    setFormattedDateFromDateMoment() {
      this.formattedValue = this.dateMoment ? this.dateMoment.format('DD/MM/YY') : '';
    },
    emitInput() {
      this.$emit('input', this.dateMoment);
    },
    dblclick() {
      if (!this.dateMoment) {
        const dateMomentAux = this.$moment();
        this.onInput(dateMomentAux);
      }
    },
  },
  watch: {
    value(newValue) {
      this.dateMoment = newValue ? this.$moment(newValue) : null;
      this.setFormattedDateFromDateMoment();
    },
  },
};
</script>

<style>
  .input-date-width {
    max-width: 150px;
    min-width: 100px;
  }
  .input-date-width .menuable__content__active {
    z-index: 300 !important;
  }
</style>
